    import React, { useState } from 'react';
    import { Grid, Typography, Card, CardMedia, CardContent, Box, Modal, Backdrop, Fade } from '@mui/material';
    import createImage from './create.png'; 
    import closeButtonImage from './closebutton.png'; 
    import tutorialImage from './tutorial.png';
    import registerImage from './register.png';
    import interfaceImage from './interface.png';
    import flowchartImage from './flowchart.png';
    import progressPicture from './progress.png';
    import sleepTechPicture from './sleeptech.png';
    import completePic from './complete.png';
    import recommednationImage from './recommendations.png';
    import surveyLinkImage from './surveylink.png';
    import prevScoringImage from './prevScoring.png';
    
    const instructions = [
        {
            step: 1,
            title: "Sign up for an account",
            description: "Before you can start scoring, you need to sign up for an account. Click 'Sign Up' to create an account. Currently we only support Google registration due to technical limitations.",
            image: registerImage,
            alt: "The registration process is relatively straightforward, using Google authentication for simple account management.",
        },
        {
            step: 2,
            title: "Complete the Tutorial",
            description: "Start by completing the tutorial. This will show you how to use the scoring interface and how to score the sessions. You will not be able to start the study until you have completed the tutorial.",
            image: tutorialImage,
            alt: "You can find the tutorial by clicking on the 'Tutorial' button in the navigation menu.",
        },
        {
            step: 3,
            title: "Create a Scoring Session",
            description: "Click on 'Create Session' to start a new scoring session. The system will set everything up for you automatically, and redirect you to the scoring interface.",
            image: createImage,
            alt: "Here you can see the button that creates a new session, when you have an already opened session this button will be grayed out.",
        },
        {
            step: 4,
            title: "Score the Session",
            description: "Review and score the sleep stages for the session following the scoring rules in the third version of the AASM scoring manual. You can go back to the tutorial if you need help. Your process is saved automatically, so if your session is interrupted you should be able to continue fairly easily. In case of instances where you are unable to continue, please contact the study administrator.",
            image: interfaceImage,
            alt: 'The scoring interface is where you will spend most of your time, reviewing and scoring the sleep data for the session. To help you get started, you can always refer back to the tutorial.',
        },
        {
            step: 5,
            title: "Recommendations",
            description: "In two out of the four sessions, you will receive a recommendation from the system. Recommendations will either be from an artificiel intelligence (AI) or a human expert. You can tell the difference by the emoji next to the recommendation. The AI recommendation will have a robot emoji, while the human recommendation will have a scientist emoji. You can choose to follow the recommendation or not, but it is important that you score the session as accurately as possible.",
            image: recommednationImage,
            alt: "Here you can see the recommendation that the system has made for you. The recommendation is shown as an emoji next to the hypnogram. On the left side is a human recommendation, and on the right side is an AI recommendation.",
        },
        {
            step: 6,
            title: "Sleep stage before the session",
            description: "The first epoch scored in the 1-hour sesson will show the previous scoring that was done by a human expert. This is communicated with a small blue arrow at the bottome left of the scoring interface.",
            image: prevScoringImage,
            alt: "The small blue arrow at the bottom left is showing that the scoring of the epoch right before the start of the current session was 'N2'."
        },
        {
            step: 7,
            title: "Close the Session",
            description: "Once you fill out the hypnogram, the 'Close Session' button automatically appears to the right of the hypnogram. Click 'Close Session' to finish your scoring session, and the system will redirect you back to the main page.",
            image: closeButtonImage,
            alt: "Here you can see the button that closes the session, it appears next to the hypnogram when you have finished scoring the session.",
        },
        {
            step: 8,
            title: "Repeat the Process",
            description: "After closing the first session, start a new one and repeat the scoring process. You can take a short break between the sessions, but it is important that you start the second session as soon as possible after closing the first one to minimize the time between the two sessions. As you progress through the study, you will be able to see your progress on the main page.",
            image: progressPicture,
            alt: "Your progress is tracked on the main page, showing you how many sessions you have completed and how many are left to score.",
        },
        {
            step: 9,
            title: "Wait One Week",
            description: "After scoring the first two sessions, you need to wait one week before scoring the next two sessions. This is to make sure that you have enough time to forget the details of the first two sessions, as this will help us evaluate the effect of recommendations on your scoring. You do not need to do anything for this study during the week off.",
            image: sleepTechPicture,
            alt: "This picture shows what ChatGPT thinks a sleep technologist who is wating for a week looks like.",
        },
        {
            step: 10,
            title: "Score Again",
            description: "After one week, log in again and repeat steps 3-6, scoring two more sessions just as you did before. Remember to start the fourth session as soon as possible after closing the third one. Remember to contact the study administrator if you have any issues or questions.",    
            image: null,
            alt: null,
        },
        {
            step: 11,
            title: "Survey",
            description: "After scoring the fourth session, you have completed the scoring part of the study. A link to the post-study survey will appear on the study page. In the survey you will be asked about your experience with the study. You will also be asked for your email, wihch will only be used to correlate your survey answers with your scoring data, so you must use the same email that you used to sign up for the study. You can find the email you used to sign up with in the profile tab of the main page.", 
            image: surveyLinkImage,
            alt: "The survey link appears on the main page after you have scored the fourth session. Click the link to fill out the survey.",
        },
        {
            step: 12,
            title: "Completion",
            description: "After taking the post-study survey, you have completed the study. You will still be able to score further sessions if you want, but only the first four sessions will be used in the evaluation of the study.",
            image: completePic,
            alt: "This is what ChatGPT thinks you will look like after completing the study. No, I haven't seen a calendar that looks like that either.",
        },
    ];

    const Instructions: React.FC = () => {
        const [open, setOpen] = useState(false);
        const [selectedImage, setSelectedImage] = useState<string | null>(null);
        const [selectedAlt, setSelectedAlt] = useState<string | null>(null);

        const handleOpen = (image: string, alt: string) => {
            setSelectedImage(image);
            setSelectedAlt(alt);
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
            setSelectedImage(null);
            setSelectedAlt(null);
        };

        return (
            <Box sx={{ padding: 4, backgroundColor: '#e0e7ff', borderRadius: 2 }}>
                {/* Title */}
                <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold', color: '#1e3a8a', marginBottom: 4 }}>
                    ScoreCraft study instructions.
                </Typography>

                {/* Introduction to the study */}

                <Typography variant="body1" sx={{ lineHeight: 1.6, fontSize: '1rem', color: '#374151', marginBottom: 4 }}>
                    Welcome to the Sleep Revolution ScoreCraft study. 
                    This study is designed to explore the effect of recommendations on the scoring of sleep data. 
                    In this study, you will be asked to score four sleep sessions, each consisting of an hour of either traditional polysomnography (PSG) or self-applied PSG data. 
                    You will only be able to score sleep stages, and you will not be asked to score any other events or artifacts. 
                    The scoring rules you should use are outlined in the third version of the American Academy of Sleep Medicine (AASM) scoring manual.
                    The study will be conducted over a period of two weeks, as shown in the timeline below.
                    On the first day you will score two sessions, and then you will have a week off before scoring the next two sessions, and finally filling out a survey.  
                    After completing the survey, your participation in the study will be complete.
                </Typography>

                <Typography variant="h5" component="h2" align='center' gutterBottom sx={{ color: '#1e40af', fontWeight: '600', marginBottom: 2 }}>
                    Time commitment
                </Typography>
                <Typography variant="body1" sx={{ lineHeight: 1.6, fontSize: '1rem', color: '#374151', marginBottom: 4 }}>
                    The entire study is expected to take approximately one hour in total to complete. 
                    This includes the time it takes to complete the tutorial, and score the four sessions.

                    We recommend that you complete the study in two sittings, but you can also take short breaks between sessions if needed. 
                    Remember that you need to complete the study within two weeks of starting it, so plan accordingly.
                </Typography>
                
                {/* small vspace */}
                <Box sx={{ height: 20 }} />
                {/* when the study closes */}
                <Typography variant="h5" component="h2" align='center' gutterBottom sx={{ color: '#1e40af', fontWeight: '600', marginBottom: 2 }}>
                    Deadline for completion
                </Typography>
                <Typography variant="body1" sx={{ lineHeight: 1.6, fontSize: '1rem', color: '#374151', marginBottom: 4 }}>
                    The study will close on the 1st of October, 2024 
                    This means that you need to have completed the study and the survey by this date, and the last day to start the study is the last week of October, 2024.
                    If you have any questions or concerns about the study deadline, please contact the study administrator.
                </Typography>
            


                {/* small vspace */}
                <Box sx={{ height: 20 }} />
                {/* Privacy and anonymity */}
                <Typography variant="h5" component="h2" align='center' gutterBottom sx={{ color: '#1e40af', fontWeight: '600', marginBottom: 2 }}>
                    Privacy and Anonymity
                </Typography>
                <Typography variant="body1" sx={{ lineHeight: 1.6, fontSize: '1rem', color: '#374151', marginBottom: 4 }}>
                    Your privacy is important to us. 
                    All data collected during the study will be stored securely and anonymized. 
                    Your email address and name will only be used to correlate your survey answers with your scoring data, and will not be shared with anyone.
                    Your place of work and country will be used to evaluate the effect of recommendations on scoring accuracy, but will not be shared with anyone. 
                    If you have any questions or concerns about privacy, please contact the study administrator.
                </Typography>

                {/* small vscpace */}
                <Box sx={{ height: 20 }} />

                {/* Contact me if there is issues */}
                <Typography variant="h5" component="h2" align='center' gutterBottom sx={{ color: '#1e40af', fontWeight: '600', marginBottom: 2 }}>
                    In case of issues, questions or feedback, please contact us.
                </Typography>

                

                {/* small vscpace */}
                <Box sx={{ height: 20 }} />
                <Typography variant="body1" sx={{ lineHeight: 1.6, fontSize: '1rem', color: '#374151', marginBottom: 4 }}>
                    If you encounter any issues or have any questions, please contact the study administrator at <a href="mailto:benedikthth@ru.is?subject=Question about MicroNyx">benedikthth@ru.is</a>.
                    We also have both a <a href="https://discord.gg/q63u6dw5UZ">Discord</a> and a <a href="https://chat.whatsapp.com/L8P5OG8nZTv48OLm3jfv8I">WhatsApp</a> group for participants to discuss the study and ask questions.
                </Typography>

                {/* small vertical space */}
                <Box sx={{ height: 20 }} />
                <Typography variant="h5" component="h2" align='center' gutterBottom sx={{ color: '#1e40af', fontWeight: '600', marginBottom: 2 }}>
                    Rough timeline of the study.
                </Typography>
                {/* Flowchart goes here, spans the width  */}
                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 4 }}>
                    <img src={flowchartImage} alt="Flowchart of the study" style={{ width: '100%', maxWidth: 800 }} /> 
                </Box>
                {/* small vertical space */}
                <Box sx={{ height: 20 }} />
                <Typography variant="h5" component="h2" align='center' gutterBottom sx={{ color: '#1e40af', fontWeight: '600', marginBottom: 2 }}>
                    Study Instructions
                </Typography>
                <Typography align='center' variant="body1" sx={{ lineHeight: 1.6, fontSize: '1rem', color: '#374151', marginBottom: 4 }}>
                    You can click on the images to view them in full size, along with a more detailed description.
                </Typography>

                {/* Instructions */}
                <Grid container spacing={4}>
                    {instructions.map((instruction, index) => (
                        <Grid item xs={12} key={index}>
                            <Card 
                                elevation={6} 
                                sx={{ 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    padding: 3, 
                                    borderRadius: 3, 
                                    backgroundColor: '#f9fafb', 
                                    overflow: 'visible',
                                    position: 'relative',
                                }}
                            >
                                {instruction.image && (
                                    <CardMedia
                                        component="img"
                                        image={instruction.image}
                                        alt={instruction.alt!}
                                        sx={{
                                            width: 120, 
                                            height: 120, 
                                            marginRight: 3,
                                            borderRadius: 2,
                                            cursor: 'pointer',
                                            border: '2px solid #1e3a8a',  // Border to highlight the image
                                            '&:hover': {
                                                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)', // Stronger shadow on hover
                                                transform: 'scale(1.05)', // Slight zoom on hover
                                            },
                                        }}
                                        onClick={() => handleOpen(instruction.image!, instruction.alt!)}
                                    />
                                )}
                                <CardContent sx={{ paddingLeft: instruction.image ? 0 : 2 }}>
                                    <Typography variant="h6" component="h2" gutterBottom sx={{ color: '#1e40af', fontWeight: '600', marginBottom: 2 }}>
                                        {`Step ${instruction.step}: ${instruction.title}`}
                                    </Typography>
                                    <Typography variant="body1" sx={{ lineHeight: 1.6, fontSize: '1rem', color: '#374151' }}>
                                        {instruction.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                {/* Modal for Image */}
                <Modal
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <Box sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '80%',
                            bgcolor: 'rgba(255, 255, 255, 0.95)', // Slightly transparent background to highlight the image
                            boxShadow: 24,
                            p: 4,
                            outline: 'none',
                            textAlign: 'center',
                            borderRadius: 3, // Round corners for a more polished look
                        }}>
                            {selectedImage && (
                                <Box 
                                    sx={{
                                        border: '3px solid #1e40af', // Border around the image
                                        padding: 2, 
                                        borderRadius: 2, 
                                        backgroundColor: '#f0f4ff' // Subtle background color to contrast the image
                                    }}
                                >
                                    <img 
                                        src={selectedImage} 
                                        alt={selectedAlt!} 
                                        style={{ 
                                            width: '100%', 
                                            maxHeight: '80vh', 
                                            objectFit: 'contain', 
                                        }} 
                                    />
                                </Box>
                            )}
                            {selectedAlt && <Typography variant="h6" sx={{ marginTop: 2, color: '#1e3a8a' }}>{selectedAlt}</Typography>}
                        </Box>
                    </Fade>
                </Modal>
            </Box>
        );
    };

    export default Instructions;
