import React from 'react';
import { Grid, Typography, Card, CardContent, Box } from '@mui/material';

const instructions = [
    {
        step: 1,
        title: "Introduction to Self-Applied PSG",
        description: `
        The self-applied polysomnography (PSG) system uses a 10-channel EEG and EOG setup. 
        EEG is referenced against the average of E3E4, while EOG is referenced against AFz. 
        Note: No chin EMG is used; instead, the EMG temporalis is used to measure muscle activity.`,
    },
    {
        step: 2,
        title: "AASM Manual v3.0 Scoring Rules",
        description: `
        Scoring for self-applied PSG follows the same general rules as traditional PSG, using the AASM Scoring Manual version 3.0. However, note the following key differences:
        - The low pass filter setting for the EEG is adjusted from 0.3 Hz to 0.5 Hz. (This is preset in the system and you do not need to adjust it manually.)
        - The slow-wave activity threshold is reduced from 75 µV to 40 µV.
        - EMG temporalis does not show a loss of muscle tone during REM sleep as the chin EMG does, meaning traditional REM scoring rules that rely on chin tone are not applicable.`,
    },
    {
        step: 3,
        title: "REM Sleep Scoring Adjustments",
        description: `
        While the EMG temporalis does not show loss of muscle tone in REM, it can still be used to score arousals. 
        Arousal during REM is scored if:
        - There is an abrupt change in the EEG to faster frequencies.
        - This is accompanied by an increase in EMG tone, following the AASM scoring rules for arousals.`,
    }
];

const SAPSGInstructions: React.FC = () => {
    return (
        <Box sx={{ padding: 4, backgroundColor: '#e0e7ff', borderRadius: 2 }}>
            <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold', color: '#1e3a8a', marginBottom: 4 }}>
                Self-Applied PSG Scoring Instructions
            </Typography>

            {/* Instructions Grid */}
            <Grid container spacing={4}>
                {instructions.map((instruction, index) => (
                    <Grid item xs={12} key={index}>
                        <Card 
                            elevation={6} 
                            sx={{ 
                                padding: 3, 
                                borderRadius: 3, 
                                backgroundColor: '#f9fafb',
                            }}
                        >
                            <CardContent>
                                <Typography variant="h6" component="h2" gutterBottom sx={{ color: '#1e40af', fontWeight: '600', marginBottom: 2 }}>
                                    {`Step ${instruction.step}: ${instruction.title}`}
                                </Typography>
                                <Typography variant="body1" sx={{ lineHeight: 1.6, fontSize: '1rem', color: '#374151' }}>
                                    {instruction.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default SAPSGInstructions;
